import { createHttpLink, ApolloLink, ApolloClient, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import apolloCache from "./cache";
import { localStorageClass } from "./local/storage";
import { get } from "lodash";

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_APOLLO_SERVER_URL,
   credentials: "same-origin",
   headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
         "Origin, X-Requested-With, Content-Type, Accept",
   },
});

const getAuthToken = async () => {
   if (!localStorageClass.isLogged()) {
      return null;
   }

   return localStorageClass.getToken();
};

// add the authorization to the headers
const authMiddleware = new ApolloLink(async (operation, forward) => {
   const token = await getAuthToken();

   if (token) {
      operation.setContext({
         headers: {
            authorization: `Bearer ${token}`,
         },
      });

      return forward(operation);
   }

   return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
         const category = get(error, "extensions.category");
         if (category === "authentication") {
            if (localStorageClass.isLogged()) {
               localStorageClass.logout();
               window.location.reload();
               return;
            }
         }
      });
   }

   if (networkError) {
      console.log(`[Network error]: ${networkError}`);
   }
});

const apolloClient = new ApolloClient({
   cache: apolloCache,
   connectToDevTools: true,
   defaultOptions: {
      watchQuery: {
         fetchPolicy: "cache-and-network",
         errorPolicy: "all",
      },
      query: {
         errorPolicy: "all",
      },
      mutate: {
         errorPolicy: "all",
      },
   },
   link: from([authMiddleware, errorLink, httpLink]),
});

export default apolloClient;
