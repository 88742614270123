import React from "react";
import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";
import UserProfile from "../../containers/Topbar/UserProfile";
import {
   NAV_STYLE_DRAWER,
   NAV_STYLE_FIXED,
   NAV_STYLE_MINI_SIDEBAR,
   TAB_SIZE,
} from "../../constants/ThemeSetting";
import { layoutSettingsVar, globalSettingsVar } from "../../apollo/local/cache";
import { useReactiveVar } from "@apollo/client";

const { Header } = Layout;

const Topbar = () => {
   var { navStyle } = useReactiveVar(layoutSettingsVar);
   var { width, sideBarCollapsed } = useReactiveVar(globalSettingsVar);
   const logoClassName = sideBarCollapsed ? "gx-d-lg-block" : "gx-d-lg-none";

   return (
      <Header style={{ padding: 0 }}>
         {navStyle === NAV_STYLE_DRAWER ||
         ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
            width < TAB_SIZE) ? (
            <div className="gx-linebar gx-mr-3">
               <i
                  className="gx-icon-btn icon icon-menu"
                  onClick={(e) => {
                     e.stopPropagation();
                     globalSettingsVar({
                        ...globalSettingsVar(),
                        navCollapsed: true,
                     });
                  }}
               />
            </div>
         ) : null}

         <Link to="/" className={`gx-d-block ${logoClassName} gx-pointer`}>
            <Typography.Title level={4} className="gx-mt-2">
               {process.env.REACT_APP_NAME}
            </Typography.Title>
         </Link>

         <ul className="gx-header-notifications gx-ml-auto">
            <li className="gx-notify">
               <UserProfile />
            </li>
         </ul>
      </Header>
   );
};

export default Topbar;
