import { Row, Col, Typography } from "antd";
import React from "react";

const Header = () => {
   return (
      <Row>
         <Col xs={24} className="gx-text-center gx-mb-5">
            <Typography.Title level={1}>
               {process.env.REACT_APP_NAME}
            </Typography.Title>
         </Col>
      </Row>
   );
};

export default Header;
