import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PrivateCASLRoute from "../../components/PrivateCASLRoute";
import IntlMessages from "../../util/IntlMessages";
import NotFound from "../../containers/NotFound";
import { useIntl } from "react-intl";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Result } from "antd";
import { MobileOutlined } from "@ant-design/icons";
import { getAdminPath, lazyWithRetry } from "../../util/router";
import { ACLSubject } from "../../constants/Enums";
import { isEmpty } from "lodash";
import ErrorBoundary from "../../components/ErrorBoundary";

//Lazy loading
const Dashboard = lazy(() => import("./dashboard"));
const Requests = lazyWithRetry(() => import("./requests"));
const InputTracks = lazyWithRetry(() => import("./input-tracks"));
const InputTrackUpdate = lazyWithRetry(() => import("./input-tracks/update/index"));
const OutputTracks = lazyWithRetry(() => import("./output-tracks"));

const Users = lazyWithRetry(() => import("./users"));
const UserInsert = lazyWithRetry(() => import("./users/insert"));
const UserUpdate = lazyWithRetry(() => import("./users/update"));
const ProfileSettings = lazyWithRetry(() => import("./profile-settings"));
const Settings = lazyWithRetry(() => import("./settings"));
const Debug = lazyWithRetry(() => import("./debug"));

const Admin = () => {
   const intl = useIntl();

   const screens = useBreakpoint();

   //isEmpty per assicurarsi che screens sia valorizzato altrimenti inizialmente a vuoto mostra per una microfrazione il componente.
   if (!isEmpty(screens) && !screens.md) {
      return (
         <Result
            icon={<MobileOutlined />}
            status="warning"
            title={intl.formatMessage({ id: "common.viewport_error" })}
         />
      );
   }

   return (
      <ErrorBoundary>
         <Suspense fallback={<IntlMessages id="common.loading" />}>
            <Switch>
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("dashboard")}
                  component={Dashboard}
               />
               <Route
                  exact
                  path={getAdminPath("profile")}
                  component={ProfileSettings}
               />

               <Route exact path={getAdminPath("requests")} component={Requests} />

               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.INPUT_TRACK}
                  exact
                  path={getAdminPath("input-tracks")}
                  component={InputTracks}
               />

               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.INPUT_TRACK}
                  exact
                  path={getAdminPath("input-tracks/update/:itemId")}
                  component={InputTrackUpdate}
               />

               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.INPUT_TRACK}
                  exact
                  path={getAdminPath("output-tracks")}
                  component={OutputTracks}
               />

               {/* ADMIN */}
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("settings")}
                  component={Settings}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("debug")}
                  component={Debug}
               />
               <PrivateCASLRoute
                  I="manage"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users")}
                  component={Users}
               />
               <PrivateCASLRoute
                  I="create"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users/insert")}
                  component={UserInsert}
               />
               <PrivateCASLRoute
                  I="update"
                  a={ACLSubject.USER}
                  exact
                  path={getAdminPath("users/update/:itemId")}
                  component={UserUpdate}
               />

               <Route path="*" component={NotFound} />
            </Switch>
         </Suspense>
      </ErrorBoundary>
   );
};

export default Admin;
