import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { Ability, AbilityBuilder, defineAbility } from "@casl/ability";
import { Roles } from "../constants/Enums";
import { includes } from "lodash";

export const AbilityContext = createContext();
const DefaultCan = createContextualCan(AbilityContext.Consumer);

export default defineAbility((can, cannot) => {});

export const isSuperAdmin = ({ user_type: userType } = {}) => {
   if (!userType) {
      return false;
   }
   return includes([Roles.SUPERADMIN], userType);
};

export const isAdmin = ({ user_type: userType } = {}) => {
   if (!userType) {
      return false;
   }
   return includes([Roles.SUPERADMIN, Roles.ADMIN], userType);
};

export function defineRulesFor(user) {
   const { can, rules } = new AbilityBuilder(Ability);

   if (user.user_type === Roles.SUPERADMIN) {
      can("manage", "all");
      return rules;
   }

   return user.acl_permissions;
}

export const Can = (props) => {
   if (props.fallback) {
      return (
         <DefaultCan {...props} passThrough>
            {(allowed) => (allowed ? props.children : props.fallback)}
         </DefaultCan>
      );
   } else {
      return <DefaultCan {...props} />;
   }
};
